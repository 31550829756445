/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './signup/state';
import Getters from './signup/getters';
import Actions from './signup/actions';
import Mutations from './signup/mutations';

export const useSignupStore = defineStore(
    'signup',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...Mutations,
        },
    },
);
