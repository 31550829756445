import {
    GET_CURRENT_STEP, GET_CURRENT_STEP_ID, GET_IS_LOADING, GET_RELATION, GET_SIGNUP, GET_STEPS,
} from './-getter-types';
import { useSignupStore } from '~/stores/signup';
import type { Signup } from '~/models/Signup';
import type { Relation } from '~/models/Relation';
import type { Step } from '~/types/Signup';

const Getters = {
    [GET_CURRENT_STEP]: (): Step | null => {
        const signupStore = useSignupStore();

        return (signupStore.steps.find(step => step.id === signupStore.currentStepId) || null);
    },
    [GET_CURRENT_STEP_ID]: (): number => {
        const signupStore = useSignupStore();

        return signupStore.currentStepId;
    },
    [GET_IS_LOADING]: (): boolean => {
        const signupStore = useSignupStore();

        return signupStore.isLoading;
    },
    [GET_RELATION]: (): Relation | null => {
        const signupStore = useSignupStore();

        return signupStore.relation;
    },
    [GET_SIGNUP]: (): Signup | null => {
        const signupStore = useSignupStore();

        return signupStore.signup;
    },
    [GET_STEPS]: (): Step[] => {
        const signupStore = useSignupStore();

        return signupStore.steps;
    },
};

export default Getters;
