import {
    NEXT_STEP,
    PREVIOUS_STEP,
    SET_CURRENT_STEP,
    SET_LOADING,
    SET_RELATION,
    SET_SIGNUP,
    SET_STEPS,
} from './-mutation-types';
import type { Signup } from '~/models/Signup';
import type { Relation } from '~/models/Relation';
import { useSignupStore } from '~/stores/signup';
import { GET_STEPS } from '~/stores/signup/-getter-types';
import type { Step } from '~/types/Signup';

const getMaxStepId = (): number => {
    const signupStore = useSignupStore();
    let maxStepId = 0;

    for (const step of signupStore[GET_STEPS]) {
        if (step.id > maxStepId) {
            maxStepId = step.id;
        }
    }

    return maxStepId;
};

const getMinStepId = (): number => {
    const signupStore = useSignupStore();
    let minStepId = getMaxStepId();

    for (const step of signupStore[GET_STEPS]) {
        if (step.id < minStepId) {
            minStepId = step.id;
        }
    }

    return minStepId;
};

const Mutations = {
    [NEXT_STEP]() {
        const signupStore = useSignupStore();

        if (signupStore.currentStepId === getMaxStepId()) {
            return;
        }

        signupStore.currentStepId += 1;
    },
    [PREVIOUS_STEP]() {
        const signupStore = useSignupStore();

        if (signupStore.currentStepId === getMinStepId()) {
            return;
        }

        signupStore.currentStepId -= 1;
    },
    [SET_CURRENT_STEP]: (stepIndex: number) => {
        const signupStore = useSignupStore();

        if (stepIndex < getMinStepId() || stepIndex > getMaxStepId()) {
            return;
        }

        signupStore.currentStepId = stepIndex;
    },
    [SET_LOADING]: (isLoading: boolean) => {
        const signupStore = useSignupStore();
        signupStore.isLoading = isLoading;
    },
    [SET_RELATION]: (relation: Relation) => {
        const signupStore = useSignupStore();
        signupStore.relation = relation;
    },
    [SET_SIGNUP]: (signup: Signup | null) => {
        const signupStore = useSignupStore();
        signupStore.signup = signup;
    },
    [SET_STEPS]: (steps: Step[]) => {
        const signupStore = useSignupStore();
        signupStore.steps = steps;
    },
};

export default Mutations;
