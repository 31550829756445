import type { Chamber } from '~/models/Chamber';
import type { Diploma } from '~/models/Diploma';
import type { Signup } from '~/models/Signup';
import type { TradeOrganisation } from '~/models/TradeOrganisation';
import { ChamberFactory } from '~/models/factories/ChamberFactory';
import { DiplomaFactory } from '~/models/factories/DiplomaFactory';
import { SignupFactory } from '~/models/factories/SignupFactory';
import { TradeOrganisationFactory } from '~/models/factories/TradeOrganisationFactory';
import BaseApiService from '~/services/api/BaseApiService';
import type { ChambersResponse } from '~/types/Chamber';
import type { DiplomasResponse } from '~/types/Diploma';
import type {
    ConfirmSignupDto,
    SignupResponse, StartSignupDto, UpdateSignupBusinessDto, UpdateSignupPersonalDto,
    UpdateSignupRequirementsDto, UpdateSignupTypeDto, WriteSignupDto,
} from '~/types/Signup';
import type { TradeOrganisationsResponse } from '~/types/TradeOrganisation';

export default class SignupService extends BaseApiService {
    private basePath = 'signup';

    public constructor() {
        super();

        this.routeNamespace = '';
    }

    /**
     * @description Start a signup.
     * @param {StartSignupDto} dto DTO of signup to start
     * @returns {Promise<Signup>} Promise with the document as payload
     */
    async start({ type, wantsToUnregister }: StartSignupDto): Promise<Signup> {
        const response = await this.basePost<SignupResponse>(
            `${this.createPath(this.basePath)}/start`,
            {
                type,
                wantsToUnregister,
            },
        );

        return (new SignupFactory()).toModel(response.data);
    }

    async fetch(checkNumber: string): Promise<Signup | null> {
        const response = await this.baseGet<SignupResponse>(`${this.createPath(this.basePath)}/${checkNumber}`);

        return (new SignupFactory()).toModel(response.data);
    }

    async updateType({ checkNumber, type, wantsToUnregister }: UpdateSignupTypeDto): Promise<Signup> {
        const response = await this.basePost<SignupResponse>(
            `${this.createPath(this.basePath)}/${checkNumber}/type`,
            {
                type,
                wantsToUnregister,
            },
        );

        return (new SignupFactory()).toModel(response.data);
    }

    async updatePersonal({ checkNumber, ...restDto }: UpdateSignupPersonalDto): Promise<Signup> {
        const response = await this.basePost<SignupResponse>(
            `${this.createPath(this.basePath)}/${checkNumber}/personal`,
            {
                ...restDto,
            },
        );

        return (new SignupFactory()).toModel(response.data);
    }

    async updateBusiness({ checkNumber, ...restDto }: UpdateSignupBusinessDto): Promise<Signup> {
        const response = await this.basePost<SignupResponse>(
            `${this.createPath(this.basePath)}/${checkNumber}/business`,
            {
                ...restDto,
            },
        );

        return (new SignupFactory()).toModel(response.data);
    }

    // eslint-disable-next-line max-statements
    async updateRequirements({ checkNumber, ...restDto }: UpdateSignupRequirementsDto): Promise<Signup> {
        const formData = new FormData();

        formData.append('files[vog]', restDto.vog);
        formData.append('files[svmnivo]', restDto.svmnivo);
        formData.append('files[sko]', restDto.sko || '');
        formData.append('files[firstOptional]', restDto.firstOptional || '');
        formData.append('files[secondOptional]', restDto.secondOptional || '');
        formData.append('explanation', restDto.explanation || '');
        formData.append('chamberId', restDto.chamberId || '');
        for (const diploma of restDto.diplomas) {
            formData.append('diplomas[]', diploma);
        }

        const response = await this.basePost<SignupResponse>(
            `${this.createPath(this.basePath)}/${checkNumber}/requirements`,
            formData,
        );

        return (new SignupFactory()).toModel(response.data);
    }

    async confirmation({ checkNumber, ...restDto }: ConfirmSignupDto): Promise<Signup> {
        const response = await this.basePost<SignupResponse>(
            `${this.createPath(this.basePath)}/${checkNumber}/confirmation`,
            {
                ...restDto,
            },
        );

        return (new SignupFactory()).toModel(response.data);
    }

    async fetchTradeOrganisationOptions({ checkNumber }: WriteSignupDto): Promise<TradeOrganisation[]> {
        const response = await this.baseGet<TradeOrganisationsResponse>(
            `${this.createPath(this.basePath)}/${checkNumber}/trade-organisations`,
        );

        return (new TradeOrganisationFactory()).toModels(response.data);
    }

    async fetchDiplomaOptions({ checkNumber }: WriteSignupDto): Promise<Diploma[]> {
        const response = await this.baseGet<DiplomasResponse>(
            `${this.createPath(this.basePath)}/${checkNumber}/diplomas`,
        );

        return (new DiplomaFactory()).toModels(response.data);
    }

    async fetchChamberOptions({ checkNumber }: WriteSignupDto): Promise<Chamber[]> {
        const response = await this.baseGet<ChambersResponse>(
            `${this.createPath(this.basePath)}/${checkNumber}/chambers`,
        );

        return (new ChamberFactory()).toModels(response.data);
    }

    static generateCheckNumber({ uniqueNumber, uuid }: Signup): string {
        return `${uuid}${uniqueNumber}`;
    }
}
