import { BaseFactory } from './BaseFactory';
import type { ReadSignupDto } from '~/types/Signup';
import { Signup } from '~/models/Signup';

export class SignupFactory extends BaseFactory<ReadSignupDto, Signup> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadSignupDto): Signup {
        const model = new Signup();

        model.uuid = dto.uuid;
        model.uniqueNumber = dto.uniqueNumber;
        model.loggedIn = dto.loggedIn;
        model.type = dto.type;
        model.wantsToUnregister = dto.wantsToUnregister;
        model.relationData = dto.relationData;
        model.companyData = dto.companyData;
        model.isAccepted = dto.isAccepted;
        model.finished = dto.finished;
        model.explanation = dto.explanation;
        model.vogPath = dto.vogPath;
        model.svmnivoPath = dto.svmnivoPath;
        model.skoPath = dto.skoPath;
        model.diplomas = dto.diplomas;

        return model;
    }
}
